<template>
    <div>
        <b-sidebar id="sidebar-add-supplier-purchase" ref="sidebar" sidebar-class="sidebar-lg" bg-variant="white" shadow
            backdrop no-header right>
            <template #default="{ hide }">
                <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                    <h5 class="mb-0">
                        Add Supplier for :
                    </h5>

                    <b-badge variant="light-primary">
                        <b-avatar size="70px" :src="sidebarImage" />
                        <span> {{ sidebarSku }}</span>

                    </b-badge>

                    <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="closeSidebar" />

                </div>

                <b-form @submit="onSubmit" @reset="onReset" class="m-2" id="FORMID">

                    <b-form-group label="Supplier:" label-for="input-supplier">
                        <b-form-select v-model="form.super_supp_id" :options="supplier_options">
                            <b-form-select-option v-for="(obj, index) in super_supp" :key="index" :value="obj['id']">{{
                                obj['name'] }}</b-form-select-option>
                        </b-form-select>
                    </b-form-group>

                    <b-form-group id="note" label-for="input-note">

                        <span class="label-text">Note<span class="mandatory-field" v-if="form.note === ''">*</span></span>
                        <b-form-input id="input-note" v-model="form.note" placeholder="Enter note"></b-form-input>
                    </b-form-group>


                    <b-form-group id="priority" label-for="input-priority">

                        <span class="label-text">Priority<span class="mandatory-field"
                                v-if="form.priority === ''">*</span></span>
                        <b-form-input id="input-priority" v-model="form.priority" placeholder="Enter priority"
                            required></b-form-input>
                    </b-form-group>

                    <b-form-group id="cost" label-for="input-cost">
                        <span class="label-text">Cost<span class="mandatory-field" v-if="form.cost === ''">*</span></span>
                        <b-form-input id="input-cost" v-model="form.cost" type="number" placeholder="Enter cost" required
                            min="0" step="0.01"></b-form-input>
                    </b-form-group>


                    <div>
                        <b-form-group id="partnumber" label-for="input-partnumber">
                            <span class="label-text">Part Number<span class="mandatory-field">*</span></span>
                            <b-form-input id="input-partnumber" v-model="form.partnumber" placeholder="Enter Part Number"
                                required></b-form-input>
                        </b-form-group>

                        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" form="FORMID" variant="success" class="mr-2"
                            @click="generatePartNumber">Generate Part Number</b-button>
                     </div>

                    <b-form-checkbox v-model="isTaxInclude">
                        Include Tax?
                    </b-form-checkbox>

                    <div class="mt-1">
                        <span>Total Price : {{ totalPrice }}</span>
                    </div>

                    <div class="mt-1">

                    </div>
                </b-form>
            </template>

            <template #footer>
                <div class="flex mb-2 ml-2">
                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" form="FORMID" variant="success" class="mr-2"
                        type="submit" @click="onSubmit">
                        Submit
                    </b-button>

                    <b-button type="reset" variant="danger" class="mr-2" form="FORMID">
                        Reset
                    </b-button>

                    <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-danger" form="FORMID"
                        @click="closeSidebar">
                        Cancel
                    </b-button>
                </div>
            </template>
        </b-sidebar>
    </div>
</template>

<script>
import {
    BSidebar, BForm, BFormGroup, BFormInput, BFormTextarea, BButton, BBadge, BFormCheckbox, BAvatar, VBToggle,
    BFormSelect, BFormSelectOption
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
    components: {
        BForm, BFormGroup, BFormInput, BFormTextarea, BButton, BBadge, BSidebar,
        BFormCheckbox, BAvatar, BFormSelect, BFormSelectOption
    },
    directives: {
        'b-toggle': VBToggle,
        Ripple,
    },
    props: {
        sidebarSku: {
            type: String,
            required: true,
        },
        sidebarImage: {
            type: String,
            required: true,
        },
        sidebarOrderProductId: {
            type: String,
            required: true,
        }
    },
    data() {
        return {
            isNameEmpty: true,
            isTaxInclude: false,
            form: {
                note: '',
                cost: '',
                priority: '',
                partnumber: '',
                totalcost: '',
                super_supp_id: null,
                product_order_id: null
            },
            super_supp: [],
        }
    },
    created() {

        this.getAllsuppliers();

    },
    mounted() {
        // Listen for the sidebar closing event
        this.$root.$on('bv::toggle::shown', (target, shown) => {
            if (target === 'sidebar-add-supplier-purchase' && !shown) {
                // The sidebar is closed, clear the form data
                this.clearForm();
            }
        });
    },
    methods: {
        generatePartNumber() {
            function getRandomChar() {
                const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
                const randomIndex = Math.floor(Math.random() * characters.length);
                return characters.charAt(randomIndex);
            }

            let randomSection = '';
            let threeSection = '';
            for (let i = 0; i < 3; i++) {
                randomSection += getRandomChar();
                threeSection += getRandomChar();
            }
            this.form.partnumber = `NSPT-${randomSection}-${threeSection}`;
        },


        getAllsuppliers() {
            this.loader = true;
            axios
                .get(process.env.VUE_APP_API + "/super-supplier")
                .then((res) => {
                    this.loader = false;
                    this.supplier_options = res["data"].map((option) => ({
                        text: option.name,
                        value: option.id,
                    }));
                })
                .catch((error) => {
                    this.loader = false;
                    console.log(error);
                });
        },

        onReset(event) {
            event.preventDefault()
            this.form.note = ''
            this.form.priority = ''
            this.form.partnumber = ''
            this.form.cost = ''
            this.form.totalcost = ''
            this.isTaxInclude = false
            this.form.super_supp_id = null

        },
        clearForm() {
            this.form.note = '';
            this.form.priority = '';
            this.form.partnumber = '';
            this.form.cost = '';
            this.form.totalcost = '';
            this.isTaxInclude = false;
            this.form.super_supp_id = null;
        },
        closeSidebar() {
            this.$root.$emit('bv::toggle::collapse', 'sidebar-add-supplier-purchase');
            this.clearForm();
        },
        onSubmit(event) {

            event.preventDefault()
            if (this.checkValid) {

                if (this.isTaxInclude) {
                    const tax = 0.1 * parseInt(this.form.cost)
                    const tPrice = parseInt(this.form.cost) + tax

                    this.form.totalcost = tPrice.toString()
                }
                else {
                    this.form.totalcost = this.form.cost
                }

                this.form['sku'] = this.sidebarSku
                this.form['product_order_id'] = this.sidebarOrderProductId

                // console.log('sidebarOrderProductId', this.sidebarOrderProductId)

                axios({
                    method: 'post',
                    url: process.env.VUE_APP_API + '/supplier',
                    data: this.form
                })
                    .then(res => {
                        if (res.data.success == true) {
                            this.$emit('refresh')
                            this.closeSidebar()

                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: 'Success',
                                    icon: 'AlertOctagonIcon',
                                    text: 'Supplier added succesfully',
                                    variant: 'success',
                                },
                            },
                                {
                                    position: 'top-right'
                                })

                            this.clearForm()
                        }
                        else if (res.data.success == false) {

                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: 'Failure',
                                    icon: 'AlertOctagonIcon',
                                    text: 'supplier added succesfully',
                                    variant: 'danger',
                                },
                            },
                                {
                                    position: 'top-right'
                                })
                        }
                    })
            }
            else {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Validation Error',
                        icon: 'AlertOctagonIcon',
                        text: 'Please fill all the required fields',
                        variant: 'danger',
                    },
                },
                    {
                        position: 'top-left'
                    })
            }
        }
    },
    computed: {
        checkValid: function () {
            return this.form.cost.length != 0 && this.form.priority.length != 0 && this.form.partnumber.length != 0 && this.form.super_supp_id != null
        },
        totalPrice: function () {
            if (this.isTaxInclude) {
                const tax = 0.1 * parseInt(this.form.cost)
                const tPrice = parseInt(this.form.cost) + tax

                return tPrice.toString()
            }
            else {
                return this.form.cost
            }
        }
    }

}
</script>

<style>
.label-text {
    display: flex;
    align-items: center;
}

.label-text {
    display: flex;
    align-items: center;
}

.mandatory-field {
    color: red;
    margin-left: 4px;
    display: inline-block;
    opacity: 0.7;
}

.mandatory-field.empty {
    color: gray;
    opacity: 1;
}
</style>