<template>
  <div v-if="suppliersData && suppliersData.length > 0">
    <b-sidebar id="sidebar-show-assignorder" sidebar-class="sidebar-lg" bg-variant="white" shadow backdrop no-header right>
      <template #default="{ hide }">
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h3>Purchase Orders</h3>
          <b-badge class="mr-6" pill variant="light-primary">
            {{ suppliersData.length }} {{ suppliersData.length === 1 ? 'Purchase Order' : 'Purchase Orders' }}
          </b-badge>
          <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
        </div>
        <div>
          <div v-for="(order, index) in suppliersData" :key="index">
            <p style="margin-top: 5%; margin-left: 3%;">
              Supplier:
              <span v-if="order.suppliers && order.suppliers.length > 0">
                <span v-for="(superSupp, superSuppIndex) in order.suppliers" :key="superSuppIndex">
                  {{ superSupp.super_supp.name }}
                  {{ superSuppIndex < order.suppliers.length - 1 ? ', ' : '' }}
                </span>
              </span>
              <span v-else>
                <p style="color: red;">Please add supplier first</p>
              </span>
            </p>
            <div style="margin-left: 10%">
              <p>
                {{ order.orderID }}
                <img :src="order.products[0].productImageUrl" width="100px"> AU ${{ order.products[0].price }}
                <feather-icon
                  class="ml-1 cursor-pointer"
                  icon="XIcon"
                  size="16"
                  @click="removeSelectedOrder(index)"
                />
              </p>
            </div>
          </div>
        </div>
      </template>
      <template #footer>
      <div class="flex mb-2 ml-2">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="success"
          class="mr-2"
          @click="savePurchaseOrder"
          :disabled="suppliersData.some(order => !(order.suppliers && order.suppliers.length > 0))"
        >
          Add in Purchase orders
        </b-button>
        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-danger" class="mr-10" @click="closeSidebar()">
          Cancel
        </b-button>
      </div>
    </template>
    </b-sidebar>
  </div>
</template>
  
<!-- if you want to disable the  button -->

<!-- :disabled="suppliersData.some(order => !(order.suppliers && order.suppliers.length > 0))" -->
  
<script>
import {
    BSidebar, BBadge, BMedia, BButton, BImg, BTable, BTableSimple, BThead, BTfoot, BTbody, BTr, BTh, BTd
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'


export default {
    components: {
        BSidebar, BBadge, BMedia, BButton, BImg, BTable, BTableSimple,
        BThead, BTfoot, BTbody, BTr, BTh, BTd, ToastificationContent
    },
    directives: {
        Ripple
    },
    data: function () {
        return {

            suppliers: []
        }
    },
    props: {
        suppliersData: {
            type: Object,
            required: true
        }
    },
    methods: {
      removeSelectedOrder(index) {
      this.suppliersData.splice(index, 1);
      if (this.suppliersData.length === 0) {
        this.reloadPage();
      }
    },
    reloadPage() {
      // Reload the page
      window.location.reload();
    },
        closeSidebar() {
            this.$root.$emit('bv::toggle::collapse', 'sidebar-show-assignorder')
        },
        
        savePurchaseOrder() {
          if (this.suppliersData.every(order => order.suppliers && order.suppliers.length > 0)) {
            axios.post(process.env.VUE_APP_API + '/add-purchaseorder', this.suppliersData)
                .then((res) => {
                    console.log('Response:', res.data); // Log the response
                    if (res.data.success) {

                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Success',
                                icon: 'AlertOctagonIcon',
                                text: 'Purchase Order Created',
                                variant: 'success',
                            },
                        },
                            {
                                position: 'top-right'
                            })
                        this.$emit('refresh')
                        this.closeSidebar()
                    }
                    else {
                      alert('wqerqwrwer');
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Failure',
                                icon: 'AlertOctagonIcon',
                                text: 'Purchase Order Cannot Be Created',
                                variant: 'danger',
                            },
                        },
                            {
                                position: 'top-right'
                            })
                    }
                })
        }
    }
    },

};
</script>
  
<style scoped>
/* table td 
  {
    table-layout:fixed;
    overflow:hidden;
    word-wrap:break-word;
  } */
  </style>
  