<template>
  <div class="position-relative">
    <b-row>


      <b-col md="6">
        <div v-if="selectedItems.length === 0">
          <p>Please select any purchase order to Assign order</p>
        </div>
        <b-button v-else v-on:click="buildSupplierData" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary"
          v-b-toggle.sidebar-show-assignorder>
          Assign Order
        </b-button>
      </b-col>

      <b-col md="6">
        <b-row>
          <b-col md="7">
            <b-form-group label-cols-sm="2" label-align-sm="right" label-size="md" label-for="filterInput">
              <b-input-group size="sm">
                <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''">
                    Clear
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group label-cols-sm="4" label-align-sm="right" label-size="sm" label-for="perPageInput">
              <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions"
                @change="savePerPage(perPage)" />
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- <pre>{{ selectedItems }}</pre> -->
    <span>Total Rows : <b>{{ totalRows }}</b></span>
    <b-col cols="12">
      <b-table ref="myTable" :items="orders" :fields="fields" striped responsive id="purchasing-table" :per-page="perPage"
        :current-page="currentPage" small class="hide-scroll-bar" :filter="filter" :filter-included-fields="filterOn"
        @filtered="onFiltered">
        <span>Total Rows : <b>{{ orders.length }}</b></span>


        <template #cell(select)="row">
          <b-form-checkbox v-if="row.item.products && row.item.products.length > 0" v-model="row.item.checkboxStatus"
            v-on:change="selected(row.item)" class="custom-control-primary">
          </b-form-checkbox>
        </template>

        <template #cell(orderID)="row">
          <span>{{ row.item.salesRecord }}</span>
          <br><br>
          <span>{{ row.item.orderID }}</span>
          <br><br>

          <span class="cursor-pointer" @click="row.toggleDetails">
            <feather-icon v-if="!row.detailsShowing" v-model="row.detailsShowing" icon="PlusIcon" />
            <feather-icon v-else icon="MinusIcon" v-model="row.detailsShowing" />
            <span>{{ row.detailsShowing ? 'Hide' : 'Show Details' }}</span>
          </span>
        </template>

        <!-- <template #cell(carrier)="row">
            <div>
              {{ row.item.carrier }}
            </div>
          </template> -->

        <!-- <template #cell(ShipTo)="row">
            <span>{{ row.item.buyerName }}<br>{{ row.item.ShipTo }}<br>{{ row.item.postcode }}</span>
          </template> -->

        <template #cell(image)="row">

          <span class="mt-1" style="list-style-type: none;" v-for="product in row.item.products" :key="product.id">
            <div v-lazy-container="{
              selector: 'img',
              error:
                'https://i.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.webp',
              loading:
                'https://i.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.webp',
            }">

              <img :src="product.productImageUrl" width="100px"><br>
            </div>
          </span>

        </template>

        <template #cell(price)="row">
          <div>
            <span v-for="product in row.item.products" :key="product.id">
              AU ${{ product.price }}
              <br><br>
            </span>
          </div>
        </template>

        <template #cell(sku)="row">
          <div style="width:170px;" v-for="product in row.item.products" :key="product.id">
            <span>{{ product.sku }}</span>
            <!-- <span class="mr-1"><b> x</b> {{ product.quantity }}</span> -->
            <span>
              <feather-icon v-b-tooltip.hover.top="'Add Supplier'" icon="PlusCircleIcon" size="18"
                v-b-toggle.sidebar-add-supplier-purchase
                @click="addSupplier(product.sku, product.productImageUrl, row.item.orderID)" />
            </span>
            <br><br>
          </div>
        </template>

        <template #cell(quantity)="row">
          <div style="width:170px;" v-for="product in row.item.products" :key="product.id">
            <span class="mr-1"><b> x</b> {{ product.quantity }}</span>
          </div>
        </template>

        <template #cell(title)="row">
          <div>
            <span v-for="product in row.item.products" :key="product.id">
              <span>{{ product.product_name }}</span>

              <br><br>
            </span>
          </div>
        </template>


        <template #cell(suppliers)="row">
          <div>
            <template v-if="row.item.suppliers && row.item.suppliers.length > 0">
              <b-table :items="row.item.suppliers" :fields="suppfields" striped responsive id="supplier-table" small
                class="hide-scroll-bar">

                <template #cell(super_supplier)="supplierRow">
                  {{ supplierRow.item.super_supp.name }}
                </template>



                <template #cell(action)="row">
                  <feather-icon icon="Edit2Icon" class="mr-50 cursor-pointer" v-b-toggle.sidebar-suppRowData
                    @click="editSupplier(row.item)" />
                  <feather-icon icon="TrashIcon" class="mr-50 cursor-pointer" @click="deleteSupplier(row.item.id)" />
                </template>

              </b-table>
            </template>
            <template v-else>
              <p>Please add supplier's</p>
            </template>
          </div>
        </template>



        <template #row-details="row">
          <b-card>
            <b-row class="mb-2">

              <b-col md="4" class="mb-1">
                <strong>Status : </strong>{{ row.item.orderStatus }}
              </b-col>


              <b-col md="4" class="mb-1">
                <strong>Username </strong>{{ row.item.buyerEbayUserName }}
              </b-col>
              <b-col md="4" class="mb-1">
                <strong>Phone : </strong>{{ row.item.phone }}
              </b-col>

              <b-col md="4" class="mb-1">
                <strong>Carrier : </strong>{{ row.item.carrier }}
              </b-col>


              <b-col md="4" class="mb-1">
                <strong>ShipTo : </strong>{{ row.item.ShipTo }}
              </b-col>

              <b-col md="4" class="mb-1">
                <strong>Post Code : </strong>{{ row.item.postcode }}
              </b-col>

              <b-col md="4" class="mb-1">
                <strong>Buyer Name : </strong>{{ row.item.buyerName }}
              </b-col>


              <b-col md="4" class="mb-1">
                <strong>Description : </strong><span v-html="row.item.Description"></span>
              </b-col>

            </b-row>

            <b-button size="sm" variant="outline-secondary" @click="row.toggleDetails">
              Hide Details
            </b-button>
          </b-card>
        </template>

      </b-table>
    </b-col>

    <b-col cols="12">
      <b-pagination :total-rows="totalRows" :per-page="perPage" v-model="currentPage" first-number last-number
        class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
        <template #prev-text>
          <feather-icon icon="ChevronLeftIcon" size="18" />
        </template>
        <template #next-text>
          <feather-icon icon="ChevronRightIcon" size="18" />
        </template>
      </b-pagination>
    </b-col>

    <show-selected-sidebar :suppliersData="suppliersData" @deselect = "deselect" @refresh="refresh" />

    <add-supp-purchase :sidebarSku="sidebarSku" :sidebarImage="sidebarImage"
      :sidebarOrderProductId="String(sidebarOrderProductId)" @refresh="refresh" />

    <edit-supp-purchase :suppRowData="suppRowData" @refresh="refresh" />

    <b-overlay :show="show" no-wrap spinner-variant="primary"></b-overlay>
  </div>
</template>

<script>
import {
  BTable, BFormCheckbox, BButton, BCard, BRow, BCol, BPagination,
  BFormRadio, VBToggle, BDropdown, VBTooltip,
  BFormGroup,
  BFormSelect,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BOverlay
} from 'bootstrap-vue'
import axios from 'axios'
import 'intersection-observer'
import Ripple from 'vue-ripple-directive'
import Swal from 'sweetalert2'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ShowSelectedSidebar from './ShowSelectedSidebar.vue'
import AddSuppPurchase from './AddSuppPurchase.vue'
import EditSuppPurchase from './EditSuppPurchase.vue'

export default {
  components: {
    BTable,
    Swal,
    BButton,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BPagination,
    BFormRadio,
    BDropdown,
    ToastificationContent,
    BFormGroup,
    BFormSelect,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    AddSuppPurchase,
    EditSuppPurchase,
    ShowSelectedSidebar,
    BOverlay
  },

  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
    'b-toggle': VBToggle,
  },

  data() {
    return {
      perPage: '25',

      pageOptions: [25, 50, 100, 200],
      currentPage: 1,
      fields: ['select', 'orderID', 'title', 'image', 'quantity', 'sku', 'price', 'suppliers'],
      suppfields: ['super_supplier', 'note', 'priority', 'totalcost', 'partnumber', 'action'],
      orders: [],
      selectedItems: [],
      suppliersData: {},
      totalRows: 1,
      filter: null,
      filterOn: [],
      sidebarSku: '',
      sidebarImage: '',
      sidebarOrderProductId: '',
      suppRowData: {},
      show: false,
      showSidebar: false,

    }
  },


  mounted() {
    this.lazyLoadImages();
  },

  computed: {
    rows() {
      return this.orders.length
    }
  },
  created() {
    this.perPage = '25'
    this.get()
  },
  watch: {
    currentPage(newPage) {
      this.scrollToTop();
    },
  },
  methods: {

    scrollToTop() {
      const tableElement = this.$refs.myTable.$el;
      if (tableElement) {
        tableElement.scrollIntoView({ behavior: 'smooth' });
      }
    },

    lazyLoadImages() {
      const images = document.querySelectorAll('img[data-src]');

      const options = {
        root: null,
        rootMargin: '0px',
        threshold: 0.1,
      };
      // console.log('Suppliers:', row.item.suppliers);

      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            const lazyImage = entry.target;
            lazyImage.src = lazyImage.getAttribute('data-src');
            lazyImage.removeAttribute('data-src');
            observer.unobserve(lazyImage);
          }
        });
      }, options);

      images.forEach(image => {
        observer.observe(image);
      });
    },

    async get() {
      this.show = true
      await axios.get(process.env.VUE_APP_API + '/orders-with-supp')
        .then(response => {
          this.orders = response['data']
          this.show = false
          this.totalRows = this.orders.length
        })
    },

    deselect(){ 
      this.selectedItems = [];
    },

    selected(id) {
      var status = false

      for (var i = 0; i < this.selectedItems.length; i++) {
        if (this.selectedItems[i] == id) {
          status = true
        }
      }
      if (status) {
        const index = this.selectedItems.indexOf(id);
        if (index > -1) {
          this.selectedItems.splice(index, 1);
        }
      }
      else {
        this.selectedItems.push(id)
      }
    },

    buildSupplierData() {

      this.suppliersData = this.selectedItems;
      console.log('selectedItems', this.selectedItems)
    },
    update(orderId, sku, supplierId) {
      var suppliers = []
      for (let i in this.selectedItems) {
        if (this.selectedItems[i].orderID != orderId) continue;
        for (let j in this.selectedItems[i].products) {
          if (this.selectedItems[i].products[j].sku != sku) continue;

          for (let k in this.selectedItems[i].products[j].suppliers) {
            if (this.selectedItems[i].products[j].suppliers[k].id == supplierId) {
              this.selectedItems[i].products[j].suppliers[k].checked = '1';
              this.selectedItems[i].products[j].suppliers[k].priority = '1'
            }
            else {
              this.selectedItems[i].products[j].suppliers[k].checked = '0';
              this.selectedItems[i].products[j].suppliers[k].priority = '2'
            }
          }
        }
      }
      for (let i in this.selectedItems) {
        for (let j in this.selectedItems[i].products) {
          for (let k in this.selectedItems[i].products[j].suppliers) {
            suppliers.push(this.selectedItems[i].products[j].suppliers[k])
          }
        }
      }

      axios({
        method: 'post',
        url: process.env.VUE_APP_API + '/update-supp-checkbox',
        data: suppliers
      }).
        then(res => console.log(res))

    },
    refresh() {
      this.get()
      this.selectedItems = []
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    addSupplier(sku, image, orderID) {

      this.sidebarSku = sku
      this.sidebarImage = image
      this.sidebarOrderProductId = orderID

    },
    showSuccessToast(message) {
      this.showToast(message, 'AlertOctagonIcon', 'success');
    },

    showErrorToast(message) {
      this.showToast(message, 'AlertOctagonIcon', 'danger');
    },

    showToast(title, icon, variant) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: title,
            icon: icon,
            text: '',
            variant: variant,
          },
        },
        {
          position: 'top-right',
        }
      );
    },
    deleteSupplier(supplierId) {
      if (supplierId) {
        Swal.fire({
          title: 'Are you sure to delete this Supplier?',
          text: '',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          cancelButtonText: 'No, cancel',
        }).then((result) => {
          if (result.isConfirmed) {
            this.loader = true;
            axios({
              method: 'delete',
              url: process.env.VUE_APP_API + '/supplier/supplier',
              data: {
                'supplierId': supplierId,
              }
            })
              .then((res) => {
                if (res.data.success) {
                  this.get();
                  this.loader = false;
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Purchase Supplier is Deleted',
                      icon: 'AlertOctagonIcon',
                      text: '',
                      variant: 'danger',
                    },
                  },
                    {
                      position: 'top-right'
                    });
                  setTimeout(() => {
                    this.get();
                  }, 500);
                }
                else {
                  this.loader = false;
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Failed',
                      icon: 'AlertOctagonIcon',
                      text: '',
                      variant: 'danger',
                    },
                  },
                    {
                      position: 'top-left'
                    });
                }
              })
              .catch((error) => {
                this.loader = false;
                console.log(error);
              });
          }
        });
      }
    },
    editSupplier(rowData) {
      this.showSidebar = true;
      this.suppRowData = Object.assign({}, rowData)
    },
  }
}
</script>



<style scoped>
.ul {
  list-style: none outside none;
  margin: 0;
  padding: 0;
  text-align: left;
}

.li {
  margin: 0 10px;
  display: inline;
}

/* Style for the No button */
.swal2-popup .swal2-actions .swal2-cancel-button {
  background-color: #dc3545 !important;
  /* Background color for No button */
  color: #fff !important;
  /* Text color for No button */
  margin-left: 10px;
  /* Adjust the margin to create a gap */
}

.hide-scroll-bar {
  overflow: hidden;
}
</style>